<template>
 <div>
  <v-card max-width="370" class="mx-auto mt-5" flat>
    <Errors v-bind:errors="errors"/>
  <v-card-title>
    <h4>Schüler:in bearbeiten</h4>
  </v-card-title>
  <v-card-text>
    <v-form @submit.prevent v-model="valid">
        
      <!-- Firstname -->
      <v-text-field 
      type="text"
      label="Vorname"
      v-model.trim="firstname"
      />

      <!-- Lastname -->
      <v-text-field 
      type="text"
      label="Nachname"
      v-model.trim="lastname"
      :rules="requiredRule"
      required
      />

      <!-- Year -->
      <v-autocomplete
      :items = "this.classes"
      label = "Klasse"
      v-model.trim="year"
      :rules="requiredRule"
      outlined
      required
      />

      <!-- school type -->
      <v-autocomplete
      :items = "this.schooltypes"
      label="Schulart"
      v-model.trim="schooltype"
      :rules="requiredRule"
      outlined
      required/>

      <!-- school -->
      <v-text-field 
      type="text"
      label="Schule"
      v-model.trim="school"
      />

      <!-- Phone -->
      <v-text-field 
      type="tel"
      label="Telefonnummer"
      v-model.trim="phone"
      />

      <!-- Phone of Parents field -->
      <v-text-field 
      type="tel"
      label="Telefonnummer der Eltern"
      v-model.trim="parentPhone"
      />

      <!-- E-Mail -->
      <v-text-field 
      type="email"
      label="E-Mail"
      v-model.trim="email"
      />

      <!-- Address field -->
      <v-textarea
      outlined
      rows="2"
      :rules="addressRule"
      background-color="accent"
      label="Adresse"
      v-model.trim="address"
      ></v-textarea>


      <v-divider class="my-8"/>


      <v-radio-group 
      label="Vertragsstatus"
      v-model="contractStatus"
      mandatory
      >
        <v-radio
          label="kein Vertrag"
          value="kein Vertrag"
        ></v-radio>
        <v-radio
          label="versendet"
          value="versendet"
        ></v-radio>
        <v-radio
          label="aktiv"
          value="aktiv"
        ></v-radio>
        <v-radio
          label="gekündigt"
          value="gekündigt"
        ></v-radio>
      </v-radio-group>

      <!-- termination Date of contract -->
      <v-text-field 
      v-if="contractStatus == 'gekündigt'"
      type="date" 
      label="gekündigt bis"
      v-model.trim="terminationDate"
      :rules="dateRule"
      required
      />

      <!-- monthly contract costs -->
      <v-text-field 
      label="Monatliche Vertragskosten"
      prefix="€"
      v-model.trim="monthlyContractCosts"
      :rules="moneyRule"
      required
      />

      <!-- costs for a single lesson without contract -->
      <v-text-field 
      label="Kosten pro (vertragsfreier) Einzelstunde"
      prefix="€"
      v-model.trim="lessonCosts"
      :rules="moneyRule"
      required
      />

    </v-form>

  </v-card-text>

  <v-card-actions>
      <v-container>
          <v-row class="my-2 mt-8">
              <v-btn
                color="secondary"
                width="100%"
                depressed
                @click="$router.push('/student/' + studentId)">
                Abbrechen
              </v-btn>
          </v-row>
          <v-row class="my-2">
              <v-btn
              width="100%"
              color="primary"
              depressed
              @click="update"
              >
                Aktualisieren
              </v-btn>
          </v-row>
        </v-container>
        
    </v-card-actions>

  </v-card>
</div> 
</template>

<script>
import { studentsCollection } from '@/firebase';
import Errors from '@/components/Errors.vue';

export default {
  name: 'EditStudent',
  props: ['studentId'],

  components: {
    Errors,
  },

  data() {
    return {
      classes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      schooltypes: [
        'Berufskolleg', 'fachliches Gymnasium', 'Förderschule', 'Gesamtschule', 
        'Grundschule', 'Gymnasium', 'Hauptschule', 'Montessori Schule', 'Realschule', 
        'Technisches Gymnasium', 'Waldorfschule', 'Werkrealschule', 
        'Wirtschaftliches Gymnasium'
      ],
      valid: false,
      errors: [],

      /* rules for the form */
      requiredRule: [
        v => !!v || 'Pflichtfeld',
      ],
      addressRule: [
        v => (v || '' ).length <= 200 || 'Gib maximal 200 Zeichen ein'
      ],
      moneyRule: [
        v => /\d*(,\d\d)?$/.test(v) || 'Gib einen Geltbetrag an.',
      ],
      dateRule: [ //checks if the input is a string of format 'yyyy-mm-dd' and a valid date. Caution! does not test for leap years. The 29.02 is always valid.
        v => /\d{4}-(02-[0-2]\d|(01|03|05|07|08|10|12)-([0-2]\d|3[0-1])|(04|06|09|11)-([0-2]\d|30))/.test(v) || "Gib ein gültiges Datum an."
      ],

      firstname: '',
      lastname: '',
      year: '',
      schooltype: '',
      school: '',
      phone: '',
      parentPhone: '',
      email: '',
      address: '',
      contractStatus: '',
      terminationDate: '',
      monthlyContractCosts: '',
      lessonCosts: '',
    }
  },

  async created() {
    this.getStudent();
  },

  methods: {

    async getStudent(){

      const studentDoc = await studentsCollection.doc(this.studentId).get();
      if (studentDoc.exists) {
        let student = studentDoc.data();
        this.firstname = student.firstname;
        this.lastname = student.lastname;
        this.year = student.year;
        this.schooltype = student.schooltype;
        if(student.school){
          this.school = student.school; //could be empty (avoid to make this undefined)
        }
        if(student.phone){
          this.phone = student.phone; //could be empty (avoid to make this undefined)
        }
        if(student.parentPhone) {
          this.parentPhone = student.parentPhone;
        }
        if(student.email){
          this.email = student.email; //could be empty (avoid to make this undefined)
        }
        if(student.address){
          this.address = student.address;
        }
        if(student.contractStatus){
          this.contractStatus = student.contractStatus;
        }
        if(student.terminationDate){
          this.terminationDate = student.terminationDate;
        }
        if(student.monthlyContractCosts){
          this.monthlyContractCosts = student.monthlyContractCosts;
        }
        if(student.lessonCosts){
          this.lessonCosts = student.lessonCosts;
        }
      } else {
        console.log('Could not find this document')
      }
    },

    update() {
      if(this.valid) {
        try{
          //update everything in studentsCollection
          studentsCollection
            .doc(this.studentId)
            .update({
              firstname: this.firstname,
              lastname: this.lastname,
              year: this.year,
              schooltype: this.schooltype,
              school: this.school,
              phone: this.phone,
              parentPhone: this.parentPhone,
              email: this.email,
              address: this.address,
              contractStatus: this.contractStatus,
              terminationDate: this.terminationDate,
              monthlyContractCosts: this.monthlyContractCosts,
              lessonCosts: this.lessonCosts,
          })

        } catch(error) {
          console.log("Error updating documents: ", error.message);
          this.errors.push({
            text: 'Die Informationen konnten nicht gespeichert werden.', 
            type:'firestore',
          });
          window.scrollTo(0,0);
        }

        if (!this.errors.length){
          this.$router.push('/student/' + this.studentId);
        }
      }
    }
  }


}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>